"use client";

import { createContext, useContext, type FC, type ReactNode } from "react";
import { useBucket } from "@/hooks/useBucket";
import { useEsiEvaluateFeatures } from "@/hooks/useEsiEvaluateFeatures";
import { useQueryFeatures } from "@/hooks/useQueryFeatures";
import { DEFAULT_REQUEST_CONTEXT } from "./constants";
import { type RequestContext } from "./types";

const RequestContext = createContext<RequestContext>(DEFAULT_REQUEST_CONTEXT);

interface Props {
  children: ReactNode;
  requestContext: RequestContext;
}

export const RequestProvider: FC<Props> = ({
  children,
  requestContext: { features: serverFeatures, ...requestContext },
}) => {
  const bucket = useBucket();

  // Preferentially merge query params over the top of features which are derived from the ESI
  // included bucket string. Note that we have to re-parse the header on the client because we
  // may edge cache the page - the server renderd features may be missing some of the evaluate
  // headers. This only works for features which don't vary the server render w/o invalidation
  const features = {
    ...serverFeatures,
    ...useEsiEvaluateFeatures(requestContext.bot),
    ...useQueryFeatures(),
  };

  if (bucket.length !== 0) {
    // IFF the user falls into a bucket, set the bucket to the request context which by default
    // comes with a sensible default of `${site}-${region}-${lang}-def` from the server. While
    // normally it's frowned upon to mutate an object, we shouldn't need the default ever again
    requestContext.bucket = bucket;
  }

  return (
    <RequestContext.Provider value={{ ...requestContext, features }}>
      {children}
    </RequestContext.Provider>
  );
};

export const useRequestContext = () => useContext(RequestContext);
