"use client";

import { useEffect, useState } from "react";
import { getEsiVars } from "../lib/getEsiVars";

export const useEsiVars = <T>(initialState: T) => {
  const [vars, setVars] = useState<T>(initialState);

  useEffect(() => {
    setVars((prev) => {
      return getEsiVars(prev);
    });
  }, []);

  return vars;
};
