export const throttle = <T extends Function>(fn: T, delay: number): T => {
  let last = 0;
  return ((...args: any) => {
    const now = Date.now();
    if (now - last < delay) {
      return;
    }
    last = now;
    fn(...args);
  }) as unknown as T;
};
