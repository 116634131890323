import { SCRIPT_ID } from "../lib/constants";

export const getEsiVars = <T>(initialState: T) => {
  if (typeof window === "undefined") {
    throw new Error("getEsiVars must be called on the client in an effect");
  }

  const el = document.getElementById(SCRIPT_ID);
  if (!el) {
    return initialState;
  }

  try {
    return JSON.parse(el.innerHTML);
  } catch (_) {}

  return initialState;
};
