export const sortObj = <T extends Record<string, any>>(obj: T): T => {
  const sorted: Record<string, any> = {};

  for (const key of Object.keys(obj).sort()) {
    const value = obj[key];
    if (typeof value === "object" && !Array.isArray(value)) {
      sorted[key] = sortObj(value);
    } else {
      sorted[key] = value;
    }
  }

  return sorted as T;
};
