export const MINIMUM_GUCE_CONSENT_AGE = 18;

export const AGE_BUCKETS = [18, 21, 25, 35, 45, 50, 55, 65, 101];

export const FEATURES = {
  /**
   * Enable prebid dynamic floor by Assertive Yield
   * @author jnaik
   */
  enableAssertiveYield: null,

  /**
   * Prebid feature: disable both live intent & live ramp
   * @author jnaik
   */
  "prebid-disableLiveIntent-disableLiveRamp": null,

  /**
   * Prebid feature: disable live intent & enable live ramp
   * @author jnaik
   */
  "prebid-disableLiveIntent-enableLiveRamp": null,

  /**
   * Prebid feature: enable live intent & disable live ramp
   * @author jnaik
   */
  "prebid-enableLiveIntent-disableLiveRamp": null,
};

export const GAM_NPA_CONSENT_KEYS = [
  "ACCOUNT_MATCHING",
  "CROSS_DEVICE_MAPPING",
  "SELL_PERSONAL_INFORMATION",
];
export const GAM_NPA_NON_CONSENT_KEYS = ["FIRST_PARTY_ADS"];
export const GAM_FIRST_PARTY_CONSENT_KEYS = ["FIRST_PARTY_ADS"];

// Currently all jursdictions use the same consent keys,
// so define them here to reduce string duplication.
// If they diverge, the logic is in place to handle that
const GAM_CONSENT_KEYS = [
  "ACCOUNT_MATCHING",
  "CROSS_DEVICE_MAPPING",
  "FIRST_PARTY_ADS",
  "SELL_PERSONAL_INFORMATION",
];

// For users under gdpr, all of these keys must be opted in, otherwise we show limited ads
export const GAM_EMEA_CONSENT_KEYS = GAM_CONSENT_KEYS;

// For users NOT under gdpr but in US, all of these keys must be opted in otherwise we show limited ads
export const GAM_US_CONSENT_KEYS = GAM_CONSENT_KEYS;

// For users rest of the world, all of these keys must be opted in otherwise we show limited ads
export const GAM_REST_OF_WORLD_CONSENT_KEYS = GAM_CONSENT_KEYS;

export const I13N_KEYS_TO_CLEAR = [
  "expn",
  "p_cpos",
  "pd",
  "pct",
  "lmsid",
  "lpstaid",
  "hashtag",
  "pstaid",
];

export const getBenjiContainerId = (
  location: string,
  site: string,
  locationNumber?: number,
): string | undefined => {
  const locationNumberSuffix = locationNumber ? `_${locationNumber}` : "";
  return site ? `${location}${locationNumberSuffix}-${site}` : undefined;
};

export const I13N_KEYS_TO_CLEAR_ELECTION = [
  "expn",
  "p_cpos",
  "pd",
  "lmsid",
  "lpstaid",
  "hashtag",
  "pstaid",
];
