"use client";

import {
  useEffect,
  useState,
  useRef,
  type RefObject,
  type MutableRefObject,
} from "react";

// Finds active item in viewport from a list of items
export const useIntersectionObserver = (
  options: IntersectionObserverInit,
  nodeRefs: MutableRefObject<Element[]>,
) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const callback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        const { isIntersecting } = entry;
        if (isIntersecting) {
          const index = nodeRefs.current.indexOf(entry.target);
          setActiveIndex(index);
        }
      });
    };

    const observer = new IntersectionObserver(callback, options);
    nodeRefs.current.forEach((element) => observer.observe(element));

    return () => {
      observer.disconnect();
    };
  }, [options, nodeRefs]);

  return activeIndex;
};

export const useInView = (
  nodeRef: MutableRefObject<Element | null>,
  options: IntersectionObserverInit = { threshold: 1 },
) => {
  const [isInView, setIsInView] = useState(false);

  useEffect(() => {
    const isSupportIO = "IntersectionObserver" in window;
    if (!isSupportIO) {
      setIsInView(true);
      return;
    }
    const node = nodeRef.current;
    const observer = new IntersectionObserver((entries) => {
      const tagName = entries[0].target.tagName;
      if (entries[0].isIntersecting) {
        setIsInView(true);
      } else if (tagName !== "svg") {
        setIsInView(false);
      }
    }, options);
    if (node) {
      observer.observe(node);
      return () => observer.unobserve(node);
    }
  }, [options, nodeRef]);

  return isInView;
};

/**
 * Checks if an element is sticky active
 * @param offsetTop The offset from the top of the viewport to trigger sticky on the element.
 * @param shouldRetrigger - Flag to determine if the sticky should retrigger.
 * @param stickyRef - Ref attached to the element being observed.
 * @param resetAtTop - Flag to determine if the sticky should reset when the user scrolls back to the top.
 * This is used for fixing safari issue where it does not return accurate values for rect/scrolls on iOS devices.
 * @returns isStickyActive - flag to determine if the element is sticky.
 */

export const useStickyActive = (
  stickyRef: RefObject<HTMLDivElement | null>,
  offsetTop: number,
  shouldRetrigger: boolean = false,
  resetAtTop: boolean = false,
) => {
  const [isStickyActive, setIsStickyActive] = useState(false);
  const initialRectBottom = useRef<number | null>(null);
  const tolerance = 2; // Small buffer to account for minor position shifts during scroll
  const topBuffer = 10; // Buffer to detect when user is close to the top of the page

  useEffect(() => {
    const currentStickyRef = stickyRef.current;

    const handleScroll = () => {
      if (!currentStickyRef) return;

      const rect = currentStickyRef.getBoundingClientRect();
      if (initialRectBottom.current === null) {
        initialRectBottom.current = rect.bottom;
      }

      if (
        resetAtTop &&
        window.scrollY < initialRectBottom.current + topBuffer &&
        rect.top > offsetTop + tolerance
      ) {
        setIsStickyActive(false);
        return;
      }

      const shouldStick = resetAtTop
        ? rect.top <= offsetTop + tolerance
        : rect.top <= offsetTop;
      if (resetAtTop && rect.top > offsetTop + tolerance) return;
      setIsStickyActive(shouldStick);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [offsetTop, resetAtTop, shouldRetrigger, stickyRef]);

  return isStickyActive;
};
