"use client";
// detect if the user agent is a bot
export const detectBot = (userAgent: string | undefined): boolean => {
  if (!userAgent) {
    return false;
  }

  // currently only google bots are in the bot pattern
  var botPattern =
    "(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google)";
  var botRegex = new RegExp(botPattern, "i");
  if (botRegex.test(userAgent)) {
    return true;
  }
  return false;
};
