"use client";

import { useEffect, useState } from "react";
import { SCREEN_SIZES, SCREEN_WIDTH, type ScreenSize } from "../lib/screens";

export type DeviceSize = "smartphone" | "tablet" | "desktop";

/* eslint-disable sort-keys */
const DEVICE_SIZE_BY_SCREEN_SIZE: Record<ScreenSize, DeviceSize> = {
  // mobile
  xs: "smartphone",
  sm: "smartphone",

  // tablet
  md: "tablet",

  // desktop
  lg: "desktop",
  xl: "desktop",
  "2xl": "desktop",
};
/* eslint-enable sort-keys */

const getCurrentScreenSize = (): ScreenSize => {
  const width = window.innerWidth;

  let currentSize: ScreenSize = "xs";
  for (const screenSize of SCREEN_SIZES) {
    if (screenSize === "xs") {
      // the default breakpoint is not present in SCREEN_WIDTH
      continue;
    }

    if (width >= SCREEN_WIDTH[screenSize]) {
      currentSize = screenSize;
    }
  }

  return currentSize;
};

/**
 * **WARNING** This hook returns `null` until after the initial client-side
 * render, so if any visible UI depends on its return value there is a risk
 * that that may lead to a layout shift, harming web vitals, so be careful
 *
 * @deprecated to discourage use except when strictly necessary
 */
export const useScreenSize = ({ updateOnResize = false } = {}) => {
  const [screenSize, setScreenSize] = useState<ScreenSize | null>(null);

  useEffect(() => {
    const updateScreenSize = () => setScreenSize(getCurrentScreenSize());

    updateScreenSize();

    if (updateOnResize) {
      window.addEventListener("resize", updateScreenSize, false);

      return () => {
        window.removeEventListener("resize", updateScreenSize, false);
      };
    }
  }, [updateOnResize]);

  return screenSize;
};

/**
 * **WARNING** This hook returns `null` until after the initial client-side
 * render, so if any visible UI depends on its return value there is a risk
 * that that may lead to a layout shift, harming web vitals, so be careful
 *
 * @deprecated to discourage use except when strictly necessary
 */
export const useDeviceSize = ({
  updateOnResize = false,
} = {}): DeviceSize | null => {
  const screenSize = useScreenSize({ updateOnResize });

  return screenSize ? DEVICE_SIZE_BY_SCREEN_SIZE[screenSize] : null;
};
