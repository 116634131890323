import { useSearchParams } from "next/navigation";
import { type FeaturesMap } from "@/configs/features";
import { getQueryFeatures } from "@/lib/request/bucket";

export const useQueryFeatures = (): FeaturesMap => {
  const searchParams = useSearchParams();
  if (!searchParams) {
    return {};
  }
  return getQueryFeatures(searchParams);
};
