"use client";

import { createContext, useContext, type FC, type ReactNode } from "react";
import { type ArticleLinkConfig } from "@/configs/article-link";
import { type FeaturesConfig } from "@/configs/features";
import { type FooterConfig } from "@/configs/footer";
import { type OtelConfig } from "@/configs/otel";

export type Config = ArticleLinkConfig &
  FeaturesConfig &
  FooterConfig &
  OtelConfig;

const ConfigContext = createContext<Config>({} as Config);

interface Props {
  children: ReactNode;
  config: Config;
}

export const ConfigProvider: FC<Props> = ({ children, config }) => {
  return (
    <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
  );
};

export const useConfigContext = () => useContext(ConfigContext);
