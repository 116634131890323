export const times = <Result = any>(
  count: number,
  callback: (index: number) => Result,
): Result[] => {
  if (count < 0) {
    throw new TypeError("Cannot call times(...) a negative number of times");
  }

  const results: Result[] = [];

  for (let index = 0; index < count; index++) {
    results.push(callback(index));
  }

  return results;
};
