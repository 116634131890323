/* eslint-disable sort-keys */

import { type Bundle } from "ycb";
import * as benji from "./benji";

export const Features = {
  /**
   * Set creator brand state to active
   * @author rcoughlin
   */
  activateBrand: null,

  /**
   * Add source to affiliate link for tracking Amazon rev. The feature flag will remove after commerceR12NCluster finish
   * @author gracelin
   */
  affiliateLinkWithSource_ssr: null,

  /**
   * Enable animatinng search labels in uh search bar
   * @author ssirpilli
   */
  animateSearchBar: null,

  /**
   * Enable Apollo GraphQL based query
   * @author vsampath
   */
  apolloQuery: null,

  /**
   * Enable use of more for you stream from astronomer for Leaderboard
   * @author nford
   */
  astroLeaderboard: null,

  /**
   * Enable use of more for you stream from astronomer for generic
   * @author dweaver01
   */
  astroStreamGeneric: null,

  /**
   * Enable use of more for you stream from astronomer for lifestyle
   * @author dweaver01
   */
  astroStreamLifestyle: null,

  /**
   * Test if caas will honor the bot query param in production
   * @author kfay
   */
  botParam: null,

  /**
   * Enable Feeds based off categories for recirc modules
   * @author cmorri
   */
  categoryFeedRecirc: null,

  /**
   * Enable Card layout for recirc
   * @author vsampath
   */
  cardRecirc: null,

  /**
   * Enable comments module for articles
   * @author weihsuehl
   */
  comments: null,

  /**
   * Hide top center mobile ad on commerce articles
   * @author dliu01
   */
  commerceRemoveTopCenterMobileAd: null,

  /**
   * Enable Comscore UDM 2.0 beacons
   * @author sbertal
   */
  comscoreUdm2: null,

  /**
   * Enable Yahoo Creators article layout
   * @author crombach
   */
  creatorsArticleLayout: null,

  /**
   * Enable dark mode support
   * @author suneil
   */
  darkmode: null,

  /**
   * Enable PFS for Universal Profile
   * @author dsinclair
   */
  enablePFS: null,

  /**
   * Enable in-body article slotting of recirculation module
   * @author willthai
   */
  enableArticleInBodyRecircSlotting: null,
  /**
   * Enable Taboola Lazy Fetch
   * @author akumar06
   */
  enableTaboolaLazyFetch: null,

  /**
   * New universal header for Homepage
   * @author etast
   */
  expheader: null,

  /**
   * Display promo bar to upsell browser extension
   * @author pkasi
   */
  extPromoBar: null,

  /**
   * Enable inbody recirc slotting after the third ad
   * @author amadan
   */
  inbodyRecircNextToAd3: null,

  /**
   * Enable lazy loading of ads
   * @author clicata
   */
  lazyAds: null,

  /**
   * Enable moreForYouHideStats (comment count, view count, readtime) module on homepage more for you stream
   * @author dweaver01
   */
  moreForYouHideStats: null,

  /**
   * Display next article bar
   * @author cmorri
   */
  nextArticleBar: null,

  /**
   * Enable open telemetry for the browser
   * @author suneil
   */
  otelBrowser: null,

  /**
   * Enable perf debug bar
   * @author suneil
   */
  perf: null,

  /**
   * Enable prestige article
   * @author mmata
   */
  prestige: null,

  /**
   * Enable preview mode for editors
   * @author kfay
   */
  preview: null,

  /**
   * Enable native ads in article recommended stream
   */
  recommendedStreamAds: null,
  /**
   * report memory when user scrolls on seamless for chrome.
   * @author dweaver01
   */
  reportMemoryUsage: null,

  /**
   * Enable seamless article scrolling
   * @author dweaver
   */
  seamless: null,

  /**
   * Enable new shopping module for special shopping events
   * @author sfreeman01
   */
  shoppingEvent: null,

  /**
   * Enable webp images
   * @author suneil
   */
  webp: null,

  /**
   * ynet
   * @author rcoughlin
   */
  ynet: null,

  /**
   * Pass through for benji features
   */
  ...benji.FEATURES,
};

export type FeaturesMap = Partial<Record<keyof typeof Features, boolean>>;

export type FeaturesConfig = {
  features: FeaturesMap;
};

export const config: Bundle<FeaturesConfig> = [
  {
    settings: ["main"],
    features: {
      comments: true,
      comscoreUdm2: true,
      extPromoBar: true,
      seamless: true,
    },
  },
];
